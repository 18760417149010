html,
body {
    overflow-x: hidden;
    font-family: 'Avenir', sans-serif;
    color: #000000;
}

#main-navbar {
    background: rgba(255, 255, 255, 0.27);
    backdrop-filter: blur(12.5px);
}

.navbtns {
    color: #000;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

#intro-block {
    align-items: center;
    justify-content: space-around;
    text-align: center;
    min-height: 100vh;
    display: flex;
}

#image-block {
    width: 100%;
    height: 200px;
}

#go-to-about {
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    position: absolute;
    transition: 0.2s linear;
    padding: 10px !important;
    margin-bottom: 0 !important;
    border-radius: 34px !important;
}

#go-to-about img {
    height: 50px;
    -webkit-animation: mover 0.7s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

/* #about, */
#what-users-say {
    background-color: #F9F8FE;
    padding-bottom: 6rem;
    margin-top: 1rem;
    padding-top: 2rem;
    justify-content: center;
    align-items: center;
}

.about-image {
    width: 60px;
}

@media screen and (max-width: 1023px) {
    .navbar {
        display: flex;
        flex-wrap: wrap;
    }

    .navbar-brand {
        min-width: 100%;
    }

    .navbar-menu {
        min-width: 300%;
    }

    .burger-btn {
        margin-left: 55%;
        min-width: 50px;
    }
}

@media screen and (min-width: 1023px) {
    .about-text {
        height: 100px;
        display: flex;
        align-items: center;
    }

    .information-text {
        display: flex;
        align-items: flex-end;
        margin-bottom: 2rem !important;
    }
}


.contacts-main {
    padding-top: 4rem;
    padding-bottom: 3rem;
    background: #161C28;
    display: flex;
    color: #FFFFFF;
    font-size: 3rem;
    align-items: center;
    justify-content: center;
}

.contacts-main a {
    color: #FFFFFF;
    margin-left: 1rem;
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


@media (max-width: 767px) {
    .mobile-margin-top {
        margin-top: 70px;
        font-size: 24px;
    }
}

@media (max-width: 767px) {
    .separator {
        height: 4px;
        background-color: #000;
        margin-bottom: 40px;
        margin-top: 12rem !important
    }

    .about-text,
    .about-image {
        margin-left: 10px;
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(70px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated-section {
    opacity: 0;
}

.slide-up-animation {
    animation: slideUp 1s forwards;
}

.animated-block {
    opacity: 0;
    transform: translateY(70px);
    transition: opacity 0.5s ease, transform 1s ease;
}

.animated-block.visible {
    opacity: 1;
    transform: translateY(0);
}

#root {
    overflow: hidden;
}

.team-block {
    margin-top: -12rem !important;
}

.block-contacts {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
}

.rooh-button {
    border-radius: 5.79px;
}

.rooh-button.contact-us {
    background-color: #50BF67;
}

.ellipse {
    position: absolute;
    width: 100%;
    height: 100%; /* Change this to 100% to cover the entire section */
    top: 0;
    background: linear-gradient(270deg, rgba(80, 191, 103, 0.3), transparent 50%);
    z-index: 1;
}

.join-us-btn {
    color: #FFFFFF;
    background: #50BF67;
}

.join-us-btn:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(#50BF67, #50BF67);
    color: #FFFFFF;
}

.join-us-btn:active {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(#50BF67, #50BF67);
    color: #FFFFFF;
}

.join-us-btn::after {
    color: #FFFFFF;
}

.join-us-btn-w {
    color: #000000;
    background: #FFFFFF;
}

.join-us-btn-w:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(#FFFFFF, #FFFFFF);
}

.join-us-btn-w:active {
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(#FFFFFF, #FFFFFF);
}


#our-products {
    padding-top: 3rem;
    padding-bottom: 5rem;
}

.block img {
    max-width: 90px;
}

.center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-text.center-content {
    min-height: 100px;
    padding-top: 2rem;
}

.about-text.center-content h1 {
    max-width: 50%;
}

h1.title {
    font-size: 2vw;
}

h1.title-mobile {
    font-size: 5vw;
    font-weight: 700;
}


.carousel-inner .carousel-item {
    transition: -webkit-transform 1s ease;
    transition: transform 1s ease;
    /*transition: transform 4s ease, -webkit-transform 4s ease;*/
}


.review-date {
    color: #B0B0B0;
    justify-content: flex-end;
}

.card-header {
    border-bottom: 0;
    box-shadow: none;
    padding-bottom: 0;
}

.card-title {
    font-size: 1.3rem;
    font-weight: 700;
}

.card-header-title {
    padding-left: 0;
    padding-right: 0;
}

.card-content {
    padding-top: 0.5rem;
}

.card.card-wus {
    min-width: 30vh;
    min-height: 35vh;
    max-height: 35vh;
    font-size: 1vw;
}

div.content {
    width: 90%;
}

#our-team,
#what-we-offer,
#vacancies {
    padding-bottom: 6rem;
    margin-top: 1rem;
    padding-top: 2rem;
    justify-content: center;
    align-items: center;
}


.scroll-container {
    overflow: hidden;
}

.card.card-with-img {
    border: none;
}


ul.vacancies-ul {
    list-style: none;
}

ul.vacancies-ul li::before {
    content: "\2022";
    color: #EA232A;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.vacancies-card {
    min-height: 397px;
}

.become-a-part-text {
    color: #FFFFFF;
    font-size: 3vw;
}

.about-1 {
padding-top: 2vw;
}

.about-2 {
    padding-top: 2vw;
}

.about-paragraph {
    font-size: 1.5vw;
    font-weight: 500;
    line-height: 150%;
}

.about-button-div {
    padding-top: 2vw;
}

@media screen and (max-width: 1300px) {
    /* Adjust styles for smaller screens, for example, when not in fullscreen mode */
    .about-paragraph, .about-button-div {
        padding-top: 1vw;
    }
}


.slick-dots li button:before {
    color: #50BF67 !important;
}

.ellipse-mobile {

    background: linear-gradient(0deg, rgba(80, 191, 103, 0.15), transparent 90%);
}